/*! CSS Used from: Embedded */
body{padding:0;margin:0;font-family:-apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,     Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;}
a{color:inherit;text-decoration:none;}
*{box-sizing:border-box;}
/*! CSS Used from: Embedded */
body{margin:0;}
a{background:rgba(0,0,0,0);}
a:active,a:hover{outline:0;}
img{border:0;}
input{color:inherit;font:inherit;margin:0;}
input[type=submit]{-webkit-appearance:button;cursor:pointer;}
input::-moz-focus-inner{border:0;padding:0;}
input{line-height:normal;}
@media print{
*{text-shadow:none!important;color:#000!important;background:rgba(0,0,0,0)!important;box-shadow:none!important;}
a,a:visited{text-decoration:underline;}
a[href]:after{content:" (" attr(href) ")";}
img{page-break-inside:avoid;}
img{max-width:100%!important;}
h2{orphans:3;widows:3;}
h2{page-break-after:avoid;}
}
*{box-sizing:border-box;}
:after,:before{box-sizing:border-box;}
body{font-family:Helvetica Neue,Helvetica,Arial,sans-serif;line-height:1.42857143;color:#333;background-color:#fff;}
input{font-family:inherit;font-size:inherit;line-height:inherit;}
a{color:#428bca;text-decoration:none;}
a:focus,a:hover{color:#2a6496;text-decoration:underline;}
a:focus{outline:thin dotted;outline:5px auto -webkit-focus-ring-color;outline-offset:-2px;}
img{vertical-align:middle;}
h2{font-family:inherit;font-weight:500;line-height:1.1;color:inherit;}
h2{margin-top:20px;margin-bottom:10px;}
h2{font-size:30px;}
.text-muted{color:#777;}
ol{margin-top:0;margin-bottom:10px;}
label{display:inline-block;max-width:100%;margin-bottom:5px;font-weight:700;}
.form-control{display:block;font-size:14px;line-height:1.42857143;color:#555;}
.form-control{width:100%;height:34px;padding:6px 12px;background-color:#fff;background-image:none;border:1px solid #ccc;border-radius:4px;box-shadow:inset 0 1px 1px rgba(0,0,0,.075);transition:border-color .15s ease-in-out,box-shadow .15s ease-in-out;}
.form-control:focus{border-color:#66afe9;box-shadow:inset 0 1px 1px rgba(0,0,0,.075),0 0 8px rgba(102,175,233,.6);}
.form-control::-moz-placeholder{color:#777;opacity:1;}
.form-control:-ms-input-placeholder{color:#777;}
.form-control::-webkit-input-placeholder{color:#777;}
.form-group{margin-bottom:15px;}
.btn{display:inline-block;margin-bottom:0;font-weight:400;text-align:center;vertical-align:middle;cursor:pointer;background-image:none;border:1px solid rgba(0,0,0,0);white-space:nowrap;padding:6px 12px;font-size:14px;line-height:1.42857143;border-radius:4px;-webkit-user-select:none;-ms-user-select:none;user-select:none;}
.btn:active:focus,.btn:focus{outline:thin dotted;outline:5px auto -webkit-focus-ring-color;outline-offset:-2px;}
.btn:focus,.btn:hover{color:#333;text-decoration:none;}
.btn:active{outline:0;background-image:none;box-shadow:inset 0 3px 5px rgba(0,0,0,.125);}
.btn-primary{color:#fff;background-color:#428bca;border-color:#357ebd;}
.btn-primary:active,.btn-primary:focus,.btn-primary:hover{color:#fff;background-color:#3071a9;border-color:#285e8e;}
.btn-primary:active{background-image:none;}
.appointment-type-name{font-weight:600;}
@media print{
a[href]:after{display:none;}
}
.select{margin-bottom:15px;padding:0;}
@media (max-width:768px){
.select{width:100%;}
}
a{color:#181818;text-decoration:underline;}
a:hover{color:#313131;}
.btn{text-decoration:none;}
.btn:active:focus,.btn:focus{outline:none;outline-offset:0;}
.btn-primary,.btn-primary:focus{background-color:#313131;color:#fff;border:none;box-shadow:inset 0 -2px 0 rgba(0,0,0,.15);}
.btn-primary:active{box-shadow:inset 0 2px 0 rgba(0,0,0,.1);}
.btn-primary:active,.btn-primary:focus,.btn-primary:hover{border:none;background-color:#3e3e3e;-webkit-tap-highlight-color:rgba(0,0,0,0);}
.error{color:#b00;}
.form-control:focus{border-color:#313131;outline:0;box-shadow:inset 0 1px 1px rgba(0,0,0,.1),0 0 5px rgba(0,0,0,.4);}
#page-loading{text-align:center;padding:30px;}
#page-loading h2{font-size:20px;}
.progress-spinner{position:relative;display:inline-block;width:5em;height:5em;margin:0 .5em -.5em;font-size:12px;text-indent:999em;overflow:hidden;animation:spin 1s steps(8) infinite;-webkit-animation:spin .8s steps(8) infinite;}
.progress-spinner:after,.progress-spinner:before{content:"";position:absolute;top:0;left:2.25em;width:.5em;height:1.5em;border-radius:.2em;background:#eee;box-shadow:0 3.5em #eee;transform-origin:50% 2.5em;-webkit-transform-origin:50% 2.5em;}
.progress-spinner:before{background:#555;}
.progress-spinner:after{transform:rotate(-45deg);-webkit-transform:rotate(-45deg);background:#777;}
body{background-color:#f6f6f6;color:#313131;font-weight:400;font-size:14px;}
.content{max-width:900px;margin:15px auto;padding-bottom:60px;border-radius:3px;box-shadow:0 10px 20px 0 rgba(0,0,0,.15);position:relative;background:#fff;}
.form-control{font-size:16px;}
.business-container{padding-left:30px;padding-right:30px;max-width:900px;margin:15px auto;}
.business-container img{margin-right:30px;max-width:100%;max-height:200px;}
.steps{padding:0;margin:0;width:100%;display:table;border-radius:4px;border-top:1px solid #e3e3e3;border-bottom:1px solid #e3e3e3;background:#f5f5f5;}
.steps li{list-style-type:none;padding:10px 0;margin:0;text-align:center;display:table-cell;width:33.333333333333336%;border-right:1px solid #e3e3e3;position:relative;height:42px;}
.steps li.active{background:#fff;margin-bottom:-1px;border-bottom:1px solid #313131;}
.steps li:last-child{border-right:none;}
.pane{padding:30px;}
@media (max-width:320px){
.steps .step-pick-appointment{width:23%;}
}
@media (min-width:1024px){
.pane{padding-left:60px;}
}
@media (max-width:640px){
.pane{padding:30px 7.5px;}
}
/*! CSS Used from: Embedded */
.content{background-color:#eeeeee;background-image:none;}
.steps{background-color:transparent;}
.steps li.active{background-color:#FFFFFF;}
body{background:#ffffff;background-image:none;}
.btn-primary,.btn-primary:focus,.btn.btn-primary:hover,.btn.btn-primary:active{
    background:#00af32;
    background-image:none;
    margin-top: 20px;
}
.steps li.active{border-bottom-color:#00af32;}
.form-control:focus{border-color:#00af32;box-shadow:none;}
/*! CSS Used from: Embedded */
body{height:100%;margin:0;padding:0;}
.link-container{color:#333;text-decoration:none;}
a:focus,a:hover{color:#333;text-decoration:none;}
/*! CSS Used keyframes */
@keyframes spin{to{transform:rotate(1turn);}}

.select .category-label, .select .select-item-box {
    display: block;
    font-weight: 400;
    cursor: pointer;
    border: 1px solid #e2e2e2;
    margin-bottom: 7.5px;
    padding: 15px 22.5px;
    outline: none;
}

/*! CSS Used from: Embedded ; media=all */
@media all{
    .select .select-item-box:hover,.select .select-item-box:active,.select input[type="radio"]:checked + .select-item-box{background-color:rgba(0,0,0,0.08);}
    }
    /*! CSS Used from: Embedded ; media=all */
    @media all{
    .select-type .duration{display:none;}
    .many-types .duration-container:before,.many-types .duration-container:after{content:"";display:none;}
    }

.form-group{
    font-weight: 100;
    margin-top: 25px;
}

.babel-ignore{
    cursor: inherit;
}
.true{
    cursor: default !important;
}
.validationMessage{
    color: red;
    font-weight: 300;
    margin-top: -12px;
    margin-left: 8px;
    height: fit-content;
}